@import url(https://fonts.googleapis.com/css?family=Quicksand);
@import url(https://fonts.googleapis.com/css?family=Lato:900);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Darker+Grotesque:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Yantramanav:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mallanna&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sarala&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*FONTS*/
.waves-effect.waves-blue .waves-ripple {
  /* The alpha value allows the text and background color
  of the button to still show through. */
  background-color: #d8d8d8 !important;
}

.blur {
  -webkit-filter: blur(4px);
          filter: blur(4px);
}

.btn {
  cursor: pointer;
}

/* COLOR PALLET */
/* Social Colors */
/* MEDIA QUERY BREAK POINTS */
/*Main Font-sizes*/
body {
  background-color: #ffffff;
}

a {
  text-decoration: none;
  color: #000;
  color: initial;
}

nav a {
  text-decoration: none;
  color: inherit;
  color: white;
}

button {
  font-family: "Quicksand", sans-serif;
}

.button-input {
  transition: opacity 0.2s;
}

.disable {
  opacity: 0;
  transition: all 0.5s;
}

.btn-post {
  background-color: #222222;
  color: white;
  width: auto;
  min-height: 10px;
  margin-top: 10px;
  font-family: "Lato", sans-serif;
  text-align: center;
}

.btn-large {
  transition: all 0.2s;
  width: inherit;
  position: relative;
  top: 0px;
  width: 200px;
  background-color: #222222 !important;
}
.btn-large:active {
  position: relative;
  top: 3px;
}

.input-field input:focus {
  border-bottom: 1px solid #222222 !important;
  box-shadow: 0 1px 0 0 #222222 !important;
}
.input-field .active {
  color: #222222 !important;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

i {
  height: auto !important;
  height: initial !important;
}

label {
  font-family: "Quicksand", sans-serif;
  width: auto !important;
  width: initial !important;
}

.lato {
  font-family: "Lato", sans-serif;
}

.quicksand {
  font-family: "Quicksand", sans-serif;
}

.logo {
  font-family: "Open Sans", sans-serif;
  font-weight: 200;
}

h1 {
  font-size: 60px;
  line-height: 65px;
  font-family: "Lato", sans-serif;
}
@media only screen and (max-width: 700px) {
  h1 {
    font-size: 37.5px;
    line-height: 40.625px;
  }
}
@media only screen and (min-width: 701px) and (max-width: 1257px) {
  h1 {
    font-size: 40px;
    line-height: 43.3333333333px;
  }
}

h2 {
  font-size: 30px;
  line-height: 35px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
@media only screen and (max-width: 700px) {
  h2 {
    font-size: 26px;
    line-height: 20px;
  }
}

h3 {
  font-size: 25px;
  line-height: 30px;
  font-family: "Quicksand", sans-serif;
}
@media only screen and (max-width: 700px) {
  h3 {
    font-size: 14.7058823529px;
  }
}
@media only screen and (min-width: 701px) and (max-width: 1257px) {
  h3 {
    font-size: 16.6666666667px;
  }
}

h4 {
  font-size: 15px;
  line-height: 20px;
  font-weight: 100;
  font-family: "Quicksand", sans-serif;
}

p {
  font-size: 18px;
  line-height: 23px;
  font-weight: 300;
  font-family: "Quicksand", sans-serif;
  color: #314455;
  color: #222;
}
@media only screen and (max-width: 700px) {
  p {
    font-size: 14px;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #222222;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-family: "Quicksand", sans-serif !important;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  top: -15px;
  /* Fade in tooltip */
  opacity: 0;
  transition: all 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #222222 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  top: -30px;
}

.display_none {
  display: none !important;
}

strong {
  font-weight: bold;
}

html {
  height: 100%;
}

body {
  background-color: #efefef;
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

button,
input[type=submit],
input[type=reset] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
button:focus,
input[type=submit]:focus,
input[type=reset]:focus {
  background-color: inherit;
}

.main_container {
  height: 100%;
}
.main_container .main {
  height: 100%;
  max-width: 600px;
  text-align: center;
  margin: auto;
  position: relative;
}
.main_container .main nav {
  background-color: #efefef;
  box-shadow: none;
  box-shadow: initial;
  display: block;
  justify-content: center;
  padding: 30px;
  height: auto;
  margin: auto;
}
@media only screen and (max-width: 700px) {
  .main_container .main nav {
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-top: 15px;
    margin-bottom: 15px;
  }
}
.main_container .main nav .welcome {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_container .main nav .welcome h4 {
  color: #222;
  margin: 0;
  margin: initial;
  font-family: "Mallanna", sans-serif;
}
.main_container .main nav .welcome h4 span {
  font-weight: 900;
}
.main_container .main nav .welcome .profilePicture {
  height: 40px;
  width: 40px;
  display: flex;
  border-radius: 100%;
  margin-left: 10px;
  background-position: top;
  background-size: cover;
}
.main_container .main nav .welcome .profilePicture img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.main_container .main nav .churchInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media only screen and (max-width: 700px) {
  .main_container .main nav .churchInfo {
    justify-content: center;
    align-items: center;
  }
}
.main_container .main nav .churchInfo .logo {
  width: 50px;
  height: 50px;
  left: 10px;
}
.main_container .main nav .churchInfo .logo img {
  width: 100%;
}
.main_container .main nav .churchInfo .orgName {
  margin: 20px;
}
.main_container .main nav .churchInfo .orgName h3 {
  color: #222;
  margin: 0;
  margin: initial;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
}
@media only screen and (max-width: 700px) {
  .main_container .main nav .churchInfo .orgName h3 {
    font-size: 13px;
  }
}
.main_container .main .rsvpListContainer {
  height: 100%;
  width: 100%;
  position: relative;
}
.main_container .main .rsvpListContainer .availableDatesScreen {
  width: 100%;
  display: inline-block;
  position: relative;
  padding-bottom: 50%;
  height: 100%;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .availableDatesScreen {
    overflow-y: scroll;
  }
}
.main_container .main .rsvpListContainer .availableDatesScreen .title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px 0;
}
.main_container .main .rsvpListContainer .availableDatesScreen .title h3 {
  font-family: "Mallanna", sans-serif;
  margin: 0;
  margin: initial;
  color: #b3b3b3;
}
.main_container .main .rsvpListContainer .availableDatesScreen .title h3 b {
  font-size: 20px;
  font-weight: 900;
  color: #8b8b8b;
  font-family: "Sarala", sans-serif;
  margin-right: 5px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .section-toggle {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #8a8a8a;
  width: 80%;
  margin: auto;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .availableDatesScreen .section-toggle {
    width: 90%;
  }
}
.main_container .main .rsvpListContainer .availableDatesScreen .section-toggle .section {
  padding: 5px;
  cursor: pointer;
}
.main_container .main .rsvpListContainer .availableDatesScreen .section-toggle .section h4 {
  margin: 0;
  margin: initial;
}
.main_container .main .rsvpListContainer .availableDatesScreen .section-toggle .section-selector {
  position: absolute;
  background-color: #437fc3;
  width: 50%;
  height: 100%;
  transition: left 0.3s;
  left: 50%;
  z-index: -1;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests {
  width: 80%;
  margin: auto;
  padding-bottom: 300px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests header h3 {
  font-weight: 900;
  font-size: 1rem;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .members .header {
  margin: 10px auto;
  text-align: left;
  font-family: "Mallanna", sans-serif;
  color: #222 !important;
  font-weight: 900;
  text-decoration: underline;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .members ul {
  margin: auto;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .members ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  cursor: pointer;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .members ul li h4 {
  margin: 0;
  margin: initial;
  font-family: "Mallanna", sans-serif;
  color: #222 !important;
  font-weight: 900;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .members ul li span {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid #222;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .members ul li span i {
  color: #efefef;
  font-size: 10px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .extra-guests-members ul li h4:hover {
  color: red !important;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .extra-guests-members ul li span {
  border: medium none currentColor;
  border: initial;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .extra-guests-members ul li span i {
  font-size: 20px;
  z-index: -1;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .extra-guests-members ul li .includeMember i {
  color: red;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .add-guests button {
  display: flex;
  margin: auto 0;
  justify-content: space-between;
  align-items: center;
  background-color: #222;
  color: #fff;
  padding: 8px 10px;
  transition: all 0.4s;
  position: relative;
  top: 0;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .add-guests button:active {
  top: 2px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .add-guests button span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 30px;
  width: 30px;
  background-color: #f15852;
  border-radius: 100%;
  color: white;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .add-guests button span i {
  font-size: 19px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .add-guests button h4 {
  margin: 0;
  margin: initial;
  font-weight: 900;
  font-family: "Mallanna", sans-serif;
  text-transform: uppercase;
  font-size: 0.9rem;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .add-guests .guest-form {
  width: 100%;
  margin: auto;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .add-guests .guest-form form .row {
  margin: 0;
  margin: initial;
  width: 100%;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .add-guests .guest-form form .row .input-field {
  background-color: #fff;
  margin: auto;
  margin: 5px 0;
  height: 50px;
  display: inline-block;
  width: 100%;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .add-guests .guest-form form .row .input-field label {
  color: rgba(34, 34, 34, 0.822);
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .add-guests .guest-form form .row .input-field input {
  margin: 0;
  margin: initial;
  border: medium none currentColor;
  border: initial;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .add-guests .guest-form form .row .input-field .active {
  background-color: #fff;
  padding: 5px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .add-guests .guest-form form .row .button {
  text-align: left;
  background-color: #222;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .add-guests .guest-form form .row .button h4 {
  font-family: "Mallanna", sans-serif;
  text-transform: none;
  text-transform: initial;
  margin: 0;
  margin: initial;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .readyToCheckIn {
  margin-top: 20px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .readyToCheckIn button {
  border: 1px solid #222;
  padding: 10px;
  position: relative;
  top: 0;
  transition: all 0.4s;
}
.main_container .main .rsvpListContainer .availableDatesScreen .my-guests .readyToCheckIn button:active {
  color: #222;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container {
  margin-top: 0px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container {
  margin: 20px auto;
  position: relative;
  width: 80%;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container {
    width: 90%;
  }
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .event {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 20px 0;
  margin: auto;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  border-bottom-right-radius: 0;
  border-bottom-right-radius: initial;
  border-bottom-left-radius: 0;
  border-bottom-left-radius: initial;
  z-index: 999;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .event h3 {
  margin: 0;
  margin: initial;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .event h4 {
  margin: 0;
  margin: initial;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .event .time-container {
  display: flex;
  align-items: center;
  justify-items: center;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .event .time-container .times {
  margin: auto;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .event .time-container .times h4 {
  font-family: "Sarala", sans-serif;
  font-weight: 500;
  font-size: 14px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .event .event-desc-container {
  text-align: left;
  display: flex;
  align-items: center;
  justify-items: center;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .event .event-desc-container .event-desc h3 {
  font-size: 15px;
  font-family: "Quicksand", sans-serif;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .event .event-desc-container .event-desc h4 {
  font-family: "Darker Grotesque", sans-serif;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .event .event-desc-container .event-desc .location {
  display: flex;
  justify-items: center;
  align-items: center;
  font-weight: 100;
  font-family: "Quicksand", sans-serif;
  font-size: 11px;
  margin-top: 5px;
  color: #1f1f1fd2;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .event .event-desc-container .event-desc .location span {
  display: flex;
  height: 7px;
  width: 7px;
  border-radius: 100%;
  background-color: #4043e4;
  margin-right: 3px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .event .userIsCheckedIn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 10px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .event .userIsCheckedIn h4 {
  color: white;
  font-weight: 900;
  font-size: 13px;
  font-family: "Darker Grotesque", sans-serif;
  text-transform: uppercase;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .checkIn-button-container {
  height: 50px;
  width: 100%;
  position: absolute;
  opacity: 0;
  margin: auto;
  z-index: -1;
  display: inline-block;
  transition: top 0.3s;
  top: 10px;
  right: 0;
  left: 0;
  opacity: 0;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .checkIn-button-container .options {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .checkIn-button-container .options .btn {
  height: 100%;
  width: 100%;
  box-shadow: none;
  box-shadow: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .checkIn-button-container .options .btn h4 {
  margin: 0;
  margin: initial;
  font-weight: 900;
  font-size: 15px;
  font-family: "Mallanna", sans-serif;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .checkIn-button-container .options .checkIn {
  background-color: #008000;
  border-bottom-left-radius: 7px;
  color: white;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .checkIn-button-container .options .checkIn:hover {
  background-color: #0cb60c;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .checkIn-button-container .options .no {
  background-color: #c7c7c7;
  border-bottom-right-radius: 7px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .events-container .event-container .checkIn-button-container .options .no:hover {
  background-color: #a5a4a4;
}
@supports (-webkit-appearance: none) {
  .main_container .main .rsvpListContainer .availableDatesScreen {
    height: calc(100vh - 56px);
    max-height: calc(100vh - 56px);
  }
}
.main_container .main .rsvpListContainer .bottomBox {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background-color: #4848f7;
  transition: all 0.4s;
  z-index: 99;
  cursor: pointer;
}
.main_container .main .rsvpListContainer .bottomBox * {
  transition: all 0.4s;
}
.main_container .main .rsvpListContainer .bottomBox .churchName {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_container .main .rsvpListContainer .bottomBox .churchName h4 {
  text-align: center;
  width: 80%;
  color: #fff;
  font-weight: 900;
  font-family: "Mallanna", sans-serif;
  margin: auto;
}
.main_container .main .rsvpListContainer .bottomBox .churchName h4 i {
  margin-left: 10px;
}
.main_container .main .rsvpListContainer .bottomBox .churchName .show-more-info {
  width: 100%;
  padding: 5px;
  background-color: #2c2ca5;
  margin: 5px 0;
}
.main_container .main .rsvpListContainer .bottomBox .more-info {
  margin-top: 10px;
  text-align: center;
  width: 80%;
  color: #fff;
  font-weight: 900;
  font-family: "Mallanna", sans-serif;
  margin: auto;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer {
  z-index: 100;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .confirmRsvpContainer {
    display: block;
  }
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp {
  width: 100%;
  background-color: #e2e2e2;
  z-index: 100;
  margin: auto;
  margin-bottom: 5px;
  transition: 0.5s;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp {
    width: 100%;
    border: 1px solid #efefef;
    box-shadow: none;
    box-shadow: initial;
  }
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #222;
  border-radius: 100%;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .close i {
  color: #fff;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo {
  position: relative;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition {
  padding: 30px 30px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition header {
  text-align: left;
  margin: 10px 0;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition header h3 {
  font-size: 20px;
  font-family: "Mallanna", sans-serif;
  font-weight: 900;
  margin: 0;
  margin: initial;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition header h4 {
  margin: 0;
  margin: initial;
  font-family: "Mallanna", sans-serif;
  color: #222 !important;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers {
  text-align: left;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul .guestsHeaders {
  margin: 10px 0;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #222;
  cursor: pointer;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul .guestsHeaders span i {
  cursor: pointer;
  font-size: 15px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul li h4 {
  margin: 0;
  margin: initial;
  font-family: "Mallanna", sans-serif;
  color: #222 !important;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul li .addMember {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid #222;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul li .addMember i {
  color: white;
  font-size: 10px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul li .removeMember {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul li .removeMember i {
  color: red;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests {
  text-align: left;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form {
  margin-bottom: 10px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form .row {
  margin: 0;
  margin: initial;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form .row .input-field {
  background-color: whitesmoke;
  margin: 15px 0;
  box-shadow: inset 0px 0px 3px #dad9d9;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form .row .input-field input {
  border: medium none currentColor;
  border: initial;
  margin-bottom: 0;
  margin-bottom: initial;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form .row .input-field input:active {
  border: medium none currentColor;
  border: initial;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form .row .input-field input:focus-within {
  border: medium none currentColor;
  border: initial;
  box-shadow: none;
  box-shadow: initial;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form .row .input-field input:focus {
  border: medium none currentColor;
  border: initial;
  border-bottom: 0 !important;
  border-bottom: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form .row .input-field .active {
  left: 0;
  top: -5px;
  margin-bottom: 10px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests .submitGuest {
  background-color: #4848f7;
  padding: 10px;
  color: white;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuestsBtn {
  font-family: "Mallanna", sans-serif;
  background-color: #4848f7;
  color: white;
  padding: 2px 10px;
  display: inline-block;
  border-radius: 10px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuestsBtn h4 {
  margin: 0;
  margin: initial;
  font-size: 13px;
  font-weight: 900;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .options {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .options .btn {
  height: 50%;
  box-shadow: none;
  box-shadow: initial;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 10px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .options .btn h4 {
  margin: 0;
  margin: initial;
  font-weight: 900;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .options .yes {
  background-color: #4CAF50;
  border-bottom-left-radius: 12px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .options .yes:hover {
  background-color: #409042;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .options .cancel {
  background-color: #c7c7c7;
  border-bottom-right-radius: 12px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .options .cancel:hover {
  background-color: #a5a4a4;
}

/*# sourceMappingURL=main.css.map */

